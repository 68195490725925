import React from "react";
import { Box, Typography } from "@mui/material";
import {
  IBranch,
  ICollaborator,
  CollaboratorDayShift,
} from "../../../../shared/types";
import dayjs from "dayjs";

import { useBranchHook } from "../../../../hooks";

type Props = {
  day: string;

  startingDate: string;
  dayIndex: number;
  collaborator: ICollaborator;
  shifts: CollaboratorDayShift[];
};
export const CollaboratorDayShiftView = ({
  day,
  startingDate,
  dayIndex,
  collaborator,
  shifts,
}: Props) => {
  const { getBranchNameById } = useBranchHook();
  const currentDay = dayjs(startingDate)
    .add(dayIndex, "day")
    .format("YYYY-MM-DD");

  const shiftForDay = shifts.find(
    (shift) =>
      shift.collaboratorId === collaborator.id && shift.shiftDate === currentDay
  );

  return (
    <Box
      key={day}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        padding: "6px",
        border: "1px solid lightgrey",
        borderRadius: "8px",
        width: "100%",
        height: "54px",
      }}
    >
      <Typography
        variant="body2"
        sx={{ minWidth: "30px", fontSize: "inherit" }}
      >
        {`${dayjs(day).format("ddd").slice(0, 1)}${dayjs(day).format("DD")}`}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "inherit" }}>
        {getBranchNameById(shiftForDay?.branchId!)}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "inherit" }}>
        {shiftForDay?.startingTime}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "inherit" }}>
        {shiftForDay?.endingTime}
      </Typography>
    </Box>
  );
};
