import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";

import { Box, Button, Grid, TextField } from "@mui/material";

import dayjs from "dayjs";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IBranch, IBranchBase } from "../../../../../shared/types";
import { branchThunks } from "../../../../../store/thunks/branch.thunks";
import { useAppDispatch } from "../../../../../hooks";
import {
  AddressSchema,
  GeoLocationSchema,
} from "../../../../../shared/yup-schemas";
import { SimpleFormikDatePicker } from "../../../../../components";

const validationSchema = Yup.object({
  name: Yup.string().required("Branch name is required"),
  address: AddressSchema,
  openingDate: Yup.date().required("Opening date is required"),
  openingHours: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().required(),
      open: Yup.string().nullable(),
      close: Yup.string().nullable(),
    })
  ),
  phoneNumber: Yup.string().required("Phone number is required"),
  whatsappNumber: Yup.string().required("Whatsapp number is required"),
  geoLocation: GeoLocationSchema,
});

type Props = {
  initialValues: IBranchBase;
};

export const BranchForm = ({ initialValues }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values: IBranchBase) => {
    if (values.id) {
      dispatch(branchThunks.updateItem(values));
    } else {
      dispatch(branchThunks.createItem(values));
    }
    navigate("/dashboard/parameters/branches");
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => {
          console.log({ values });
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Branch Name"
                    fullWidth
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.line1"
                    label="Address Line 1"
                    fullWidth
                    error={touched.address?.line1 && !!errors.address?.line1}
                    helperText={touched.address?.line1 && errors.address?.line1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.line2"
                    label="Address Line 2"
                    fullWidth
                    error={touched.address?.line2 && !!errors.address?.line2}
                    helperText={touched.address?.line2 && errors.address?.line2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.city"
                    label="City"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.state"
                    label="State"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.zip"
                    label="Zip Code"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address.country"
                    label="Country"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleFormikDatePicker
                    name="openingDate"
                    label="Opening Date"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="phoneNumber"
                    label="Phone Number"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="whatsappNumber"
                    label="Whatsapp Number"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="geoLocation.latitude"
                    label="Latitude"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="geoLocation.longitude"
                    label="Longitude"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldArray name="openingHours">
                    {({ remove, push }) => (
                      <>
                        {values.openingHours.map((hour, index) => (
                          <Box key={index} mb={2}>
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  name={`openingHours[${index}].day`}
                                  label="Day"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Field
                                  as={TextField}
                                  name={`openingHours[${index}].open`}
                                  label="Open Time"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Field
                                  as={TextField}
                                  name={`openingHours[${index}].close`}
                                  label="Close Time"
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Save Changes
                  </Button>
                </Grid>
              </Grid>

              {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
