import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  useBranchHook,
  useJobsHook,
} from "../../../../hooks";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createNewShift,
  getWeekDatesByDate,
  parseDroppableId,
} from "../../../../shared/helpers";
import { jobThunks } from "../../../../store/thunks";
import { CollaboratorDayShift, IWeekShiftBase } from "../../../../shared/types";
import { setHasUnsavedChanges } from "../../../../store/slice";
import {
  ModelsSection,
  WeekShiftsTableSection,
  WeeksShiftsCollaboratorsSection,
  CollaboratorsWeekShiftSection,
} from ".";
import { useFormStatusHook, useWeekShiftsHook } from "../../../../shared/hooks";
import dayjs from "dayjs";

type Props = {
  weekShift: IWeekShiftBase;
  handleSaveWeekShift: (weekShift: IWeekShiftBase) => Promise<void>;
  fetchWeekShift: () => void;
};
export const WeekShiftsForm = ({
  weekShift,
  handleSaveWeekShift,
  fetchWeekShift,
}: Props) => {
  // *****GLOBALS***** //

  // *****CUSTOM HOOKS***** //
  const dispatch = useAppDispatch();
  const { sortedCollaboratorsByJob } = useJobsHook();
  const { setIsSaving: setIsSaving } = useFormStatusHook();
  const { getBranchByName } = useBranchHook();
  const { expandShifts } = useWeekShiftsHook();

  // *****SELECTORS***** //
  const { branches } = useAppSelector((state) => state.branches);

  // *****STATES***** //
  const [openSections, setOpenSections] = useState({
    collaboratorsCards: true,
    workShiftsTable: true,
    collaboratorsTables: true,
    copyCalendar: true,
  });
  const [shifts, setShifts] = useState<CollaboratorDayShift[]>(
    weekShift.shifts
  );

  // *****DATA INITIALIZATION***** //
  const weekDates = useMemo(
    () => getWeekDatesByDate(weekShift.startingDate),
    [weekShift.startingDate]
  );
  const displayCollaborators = useMemo(() => {
    return sortedCollaboratorsByJob.filter((collaborator) => {
      const hasStarted =
        !collaborator.startDate ||
        dayjs(collaborator.startDate).isBefore(weekShift.endingDate);
      const hasNotEnded =
        !collaborator.endDate ||
        dayjs(collaborator.endDate).isAfter(weekShift.startingDate);

      return hasStarted && hasNotEnded && collaborator.coverShift;
    });
  }, [sortedCollaboratorsByJob, weekShift.startingDate, weekShift.endingDate]);

  const expandedShifts = expandShifts(shifts || [], sortedCollaboratorsByJob);
  console.log({
    sorted: expandedShifts.map((shift) => ({
      col_code: shift.collaborator.col_code,
      date: shift.shiftDate,
    })),
  });

  const sortedShifts = useMemo(() => {
    const collaboratorOrderMap = new Map(
      sortedCollaboratorsByJob.map((collaborator, index) => [
        collaborator.id,
        index,
      ])
    );

    return shifts.sort((a, b) => {
      const collaboratorAIndex = collaboratorOrderMap.get(a.collaboratorId);
      const collaboratorBIndex = collaboratorOrderMap.get(b.collaboratorId);

      return (collaboratorAIndex ?? 0) - (collaboratorBIndex ?? 0);
    });
  }, [shifts, displayCollaborators]);

  // *****USE EFFECTS***** //
  useEffect(() => {
    dispatch(jobThunks.fetchItems());
  }, [dispatch]);

  useEffect(() => {
    setShifts(weekShift.shifts);
  }, [weekShift]);

  // *****HANDLER FUNCTIONS***** //
  const handleSubmit = async () => {
    setIsSaving(true);
    const clonedShifts = structuredClone(shifts);
    const newWeekShift = {
      ...weekShift,
      shifts: [...clonedShifts].map((shift) => ({
        ...shift,
        _id: undefined,
      })),
    };
    await handleSaveWeekShift(newWeekShift);
    setIsSaving(false);
  };

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }
      const { destDate, destBranch } = parseDroppableId(
        destination.droppableId
      );
      const isRemote = destBranch === "remote";
      const branchId = isRemote ? undefined : destBranch;
      const montejoBranchId = getBranchByName("montejo");

      dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
      setShifts((prevShifts) => {
        const collaboratorId = draggableId;
        let updatedShifts: CollaboratorDayShift[] = Array.from(prevShifts);

        if (source.droppableId === "collaborators") {
          const existingShift = updatedShifts.find(
            (shift) =>
              shift.collaboratorId === collaboratorId &&
              shift.shiftDate === destDate
          );

          if (existingShift) {
            updatedShifts = updatedShifts.map((shift) =>
              shift.id === existingShift.id
                ? { ...shift, branchId, isRemote }
                : shift
            );
          } else {
            const newShift = createNewShift(
              branchId!,
              destDate,
              collaboratorId,
              montejoBranchId!.id,
              isRemote
            );

            updatedShifts.push(newShift);
          }
        } else {
          const [movedShift] = updatedShifts.splice(
            updatedShifts.findIndex((shift) => shift.id === draggableId),
            1
          );

          const updatedShift = {
            ...movedShift,
            shiftDate: destDate,
            branchId: destBranch,
            isRemote,
          };

          updatedShifts.splice(destination.index, 0, updatedShift);
        }
        return updatedShifts;
      });
    },
    [getBranchByName]
  );

  const handleCopyShifts = (shifts: CollaboratorDayShift[]) => {
    setShifts(shifts);
    dispatch(setHasUnsavedChanges({ hasUnsavedChanges: true }));
  };

  console.log({
    sortedCollaboratorsByJob: sortedCollaboratorsByJob.map(
      (col) => col.col_code
    ),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* Copy calendar section */}

          <ModelsSection
            weekShift={weekShift}
            isCollapsed={!openSections.copyCalendar}
            toggleSection={toggleSection}
            fetchWeekShift={fetchWeekShift}
            handleCopyShifts={handleCopyShifts}
          />

          {/* Collaborator cards section */}

          <WeeksShiftsCollaboratorsSection
            collaborators={displayCollaborators}
            isCollapsed={!openSections.collaboratorsCards}
            toggleSection={toggleSection}
          />

          {/* Work shifts table section */}
          <WeekShiftsTableSection
            isCollapsed={!openSections.workShiftsTable}
            toggleSection={toggleSection}
            handleSubmit={handleSubmit}
            shifts={expandedShifts}
            weekDates={weekDates}
            collaborators={displayCollaborators}
            setShifts={setShifts}
          />
          {/* Collaborators tables section */}
          <CollaboratorsWeekShiftSection
            isCollapsed={!openSections.collaboratorsTables}
            toggleSection={toggleSection}
            collaborators={displayCollaborators}
            handleSubmit={handleSubmit}
            weekDates={weekDates}
            shifts={sortedShifts}
            startingDate={weekShift.startingDate}
            branches={branches}
            setShifts={setShifts}
          />
        </Box>
      </DragDropContext>
      <pre>{JSON.stringify(shifts, null, 2)}</pre>
    </LocalizationProvider>
  );
};
