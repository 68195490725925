import { createAsyncThunk } from "@reduxjs/toolkit";
import { fireSwalError } from "../../helpers";
import { BaseService, HandleErrorsService } from "../../services";
import { AxiosParams, IBaseEntity } from "../types";

export const handleAsyncThunkError = (error: any, rejectWithValue: any) => {
  fireSwalError(HandleErrorsService.handleError(error));
  return rejectWithValue(error);
};

interface CustomMethods<B, T> {
  update?: (item: B) => Promise<T>;
}

interface createCRUDThunksParams<T, B> {
  service: BaseService<T, B>;
  resourceName: string;
  customMethods?: CustomMethods<B, T>;
}
export const createCRUDThunks = <T extends IBaseEntity, B extends IBaseEntity>({
  service,
  resourceName,
  customMethods = {},
}: createCRUDThunksParams<T, B>) => {
  const fetchItems = createAsyncThunk(
    `${resourceName}/fetchItems`,
    async (params: AxiosParams | undefined = {}, { rejectWithValue }) => {
      try {
        const data = await service.getAll(params);
        return data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const getItemById = createAsyncThunk(
    `${resourceName}/getItemById`,
    async (id: string, { rejectWithValue }) => {
      try {
        const data = await service.getById(id);
        return data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const createItem = createAsyncThunk(
    `${resourceName}/createItem`,
    async (item: B, { rejectWithValue }): Promise<T> => {
      try {
        const data = await service.create(item);
        return data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const updateItem = createAsyncThunk(
    `${resourceName}/updateItem`,
    async (item: B, { rejectWithValue }) => {
      try {
        const data = customMethods.update
          ? await customMethods.update(item)
          : await service.update(item.id!, item);
        return data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const deleteItem = createAsyncThunk(
    `${resourceName}/deleteItem`,
    async (id: string, { rejectWithValue }) => {
      try {
        await service.delete(id);
        return id;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  return {
    fetchItems,
    getItemById,
    createItem,
    updateItem,
    deleteItem,
  };
};
