import { useNavigate } from "react-router-dom";
import { WebAppRole } from "../shared/types";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export function useAuthHook() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.authReducer);

  const { user } = auth;
  const { role, uid } = user || {};

  const isManager = role === WebAppRole.manager;
  const isAdmin = role === WebAppRole.admin;

  const isOwner = (ownerId: string) => ownerId === uid;

  const isOwnerOrManager = (ownerId: string) =>
    isOwner(ownerId) || isManager || isAdmin;

  const isAdminOrManager = isAdmin || isManager;

  const authBlock = (link?: string) => {
    if (isAdminOrManager) return;
    navigate(link || "/dashboard");
  };

  return {
    isOwnerOrManager,
    isAdminOrManager,
    isAuthenticated: auth.isAuthenticated,
    currentUserId: auth.user?.uid,
    currentUserRole: auth.user?.role,
    authBlock,
  };
}
