import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import dayjsWithPlugins from "../../config/dayjs";
dayjs.extend(isoWeek);

export const getWeekDatesByDate = (date: string | Date) => {
  // I want a function that receives a date in string or date and it should return an array of dates in this format "YYYY-MM-DD". Using dayjs
  const startOfWeek = dayjs(date).startOf("isoWeek");
  return Array.from({ length: 7 }, (_, index) =>
    startOfWeek.add(index, "day").format("YYYY-MM-DD")
  );
};

export const formatDate = (date?: string | Date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD-MMM-YYYY");
};

export const formatDateToDateDay = (date?: string | Date) => {
  if (!date) return "N/A";
  const dayObj = dayjs(date);
  const dayAbbr =
    dayObj.format("ddd").charAt(0).toUpperCase() +
    dayObj.format("ddd").slice(1, 3); // Get capitalized day abbreviation (e.g., "Lun")
  const formattedDate = dayObj.format("DD/MMM"); // Get date in format "30/Sep"

  return `${dayAbbr}-${formattedDate}`;
};

export const isSaturdayOrSunday = (date: string): boolean => {
  const day = dayjs.utc(date).day();
  return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

export const isSunday = (date: string): boolean => {
  return dayjs.utc(date).day() === 0;
};

export const getMonday = (date?: Date | dayjs.Dayjs | string): Date => {
  let inputDate: Date;

  if (dayjs.isDayjs(date)) {
    // If it's a dayjs object, convert to a native Date
    inputDate = (date as dayjs.Dayjs).toDate();
  } else if (typeof date === "string") {
    // If it's a string, try to parse it into a Date
    inputDate = new Date(date);
  } else {
    // Otherwise, use the given date or the current date
    inputDate = date ? new Date(date) : new Date();
  }

  // Handle invalid date
  if (isNaN(inputDate.getTime())) {
    throw new Error("Invalid date provided");
  }

  // Adjust to get the previous or current Monday
  const day = inputDate.getDay();
  const diff = day === 0 ? -6 : 1 - day; // If it's Sunday (0), go back 6 days, else go back to Monday
  const monday = new Date(inputDate);
  monday.setDate(inputDate.getDate() + diff);

  return monday;
};

export const getSunday = (date?: Date | dayjs.Dayjs | string): Date => {
  const monday = getMonday(date);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  return sunday;
};

export const dateIntoStringDate = (date: Date | string) => {
  return dayjsWithPlugins(date).format("YYYY-MM-DD");
};
