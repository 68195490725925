import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAppDispatch } from "../../../../hooks";
import { CollaboratorService } from "../../../../services";
import { ICollaborator } from "../../../../shared/types"; // Adjust imports based on your setup
import { CollaboratorCard } from "../components/CollaboratorCard";

export const CollaboratorIndex: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [collaborator, setCollaborator] = useState<ICollaborator>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchById = async () => {
      try {
        const service = new CollaboratorService();
        const collaborator = await service.getById(id!);
        setCollaborator(collaborator);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchById();
  }, [dispatch, id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!collaborator) {
    return <div>Collaborator not found</div>;
  }

  // todo update images

  return <CollaboratorCard collaborator={collaborator} />;
};
