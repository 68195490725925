import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import { AxiosParams } from "../shared/types";

export class BaseService<T, B = T> {
  protected API_URL: string;

  constructor(API_URL: string) {
    this.API_URL = API_URL;
  }

  async getAll(params?: AxiosParams): Promise<T[]> {
    const response = await hvpAuthenticatedApi.get(this.API_URL, { params });
    return response.data.data;
  }

  async getById(id: string): Promise<T> {
    const response = await hvpAuthenticatedApi.get(`${this.API_URL}/${id}`);
    return response.data.data;
  }

  async create(resource: B): Promise<T> {
    const response = await hvpAuthenticatedApi.post(this.API_URL, resource);
    return response.data.data;
  }

  async update(id: string, resource: B): Promise<T> {
    const response = await hvpAuthenticatedApi.patch(
      `${this.API_URL}/${id}`,
      resource
    );
    return response.data.data;
  }

  async delete(id: string): Promise<void> {
    await hvpAuthenticatedApi.delete(`${this.API_URL}/${id}`);
  }
}
