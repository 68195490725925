import { useCallback, useMemo } from "react";

import {
  CollaboratorDayShift,
  ExtendedCollaboratorDayShift,
  ICollaborator,
  IJob,
  IWeekShiftBase,
} from "../types";
import { useAppSelector, useBranchHook } from "../../hooks";
import { WeekShiftService } from "../../services";
import { dateIntoStringDate, getMonday, getSunday } from "../helpers";

const initialEmptyValues: IWeekShiftBase = {
  startingDate: dateIntoStringDate(getMonday()),
  endingDate: dateIntoStringDate(getSunday()),
  shifts: [],
};

export const useWeekShiftsHook = () => {
  const { jobs } = useAppSelector((state) => state.jobs);
  const { getBranchById } = useBranchHook();
  const service = new WeekShiftService();

  const expandShifts = useCallback(
    (shifts: CollaboratorDayShift[], collaborators: ICollaborator[]) => {
      return shifts.map((shift): ExtendedCollaboratorDayShift => {
        const collaborator = collaborators.find(
          (collaborator) => collaborator.id === shift.collaboratorId
        );
        const job = jobs.find((job) => job.id === collaborator?.jobId);
        const branch = getBranchById(shift.branchId!);
        return {
          ...shift,
          collaborator: collaborator!,
          job: job!,
          branch: branch!,
        };
      });
    },
    [jobs, getBranchById]
  );

  const fetchWeekShift = async (
    date: Date,
    setWeekShift: (weekShift: IWeekShiftBase) => void
  ) => {
    try {
      const weekShift = await service.getAll({
        startingDate: dateIntoStringDate(date),
      });

      if (weekShift[0]) {
        setWeekShift(weekShift[0]);
      } else {
        const newWeekShift: IWeekShiftBase = {
          ...initialEmptyValues,
          startingDate: dateIntoStringDate(getMonday(date)),
          endingDate: dateIntoStringDate(getSunday(date)),
        };
        setWeekShift(newWeekShift);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return { expandShifts, fetchWeekShift };
};
