import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ExtendedCollaboratorDayShift,
  CollaboratorDayShift,
  ICollaborator,
} from "../../../../shared/types";
import {
  formatDateToDateDay,
  getCollaboratosWithoutShiftByDate,
} from "../../../../shared/helpers";
import { WeekShiftRowTable } from "./WeekShiftRowTable";
import { WeekShiftRestRow } from "./WeekShiftRestRow";
import { useBranchHook } from "../../../../hooks";

type BaseProps = {
  shifts: ExtendedCollaboratorDayShift[];
  weekDates: string[];
  collaborators: ICollaborator[];
};

type EditableProps = {
  editable: true;
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
};
type NonEditableProps = {
  editable: false;
};

type Props = BaseProps & (EditableProps | NonEditableProps);

export const WeekShiftsTable = (props: Props) => {
  const { shifts, weekDates, editable, collaborators } = props;

  const collaboratorsWithoutShiftByDate = useMemo(() => {
    return getCollaboratosWithoutShiftByDate(collaborators, shifts, weekDates);
  }, [collaborators, shifts, weekDates]);

  const remoteShifts = shifts.filter((shift) => shift.isRemote);
  const { branches } = useBranchHook();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "auto" }}>
            <Typography fontWeight={600}>Branch</Typography>
          </TableCell>
          {weekDates.map((date, index) => (
            <TableCell key={index} sx={{ width: "calc(100% / 7)" }}>
              {" "}
              <Typography fontWeight={600}>
                {formatDateToDateDay(date)}
              </Typography>
              {/* Equal width for day columns */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {/* Each branch have a row */}
        {branches.map((branch, branchIndex) => {
          const branchShifts = shifts.filter(
            (shift) => shift.branchId === branch.id
          );
          return (
            <WeekShiftRowTable
              key={branchIndex}
              rowLabel={branch.name}
              branchId={branch.id}
              weekDates={weekDates}
              shifts={branchShifts}
              {...(props.editable
                ? {
                    editable: true,
                    setShifts: props.setShifts,
                  }
                : { editable: false })}
            />
          );
        })}
        {/* Remote Row */}
        <WeekShiftRowTable
          key="remote"
          rowLabel="Remote"
          branchId="remote"
          shifts={remoteShifts}
          weekDates={weekDates}
          {...(editable
            ? {
                editable: true,
                setShifts: props.setShifts,
              }
            : { editable: false })}
        />
        {/* REST ROW Row */}
        <WeekShiftRestRow
          collaboratorsByDate={collaboratorsWithoutShiftByDate}
          weekDates={weekDates}
        />
      </TableBody>
    </Table>
  );
};
