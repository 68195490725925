import { useCallback } from "react";
import { useAppSelector } from "./useAppSelector";

export const useBranchHook = () => {
  const { branches } = useAppSelector((state) => state.branches);

  const getBranchById = useCallback(
    (id: string) => {
      return branches.find((branch) => branch.id === id);
    },
    [branches]
  );

  const getBranchNameById = useCallback(
    (id: string) => {
      return branches.find((branch) => branch.id === id)?.name;
    },
    [branches]
  );

  const getBranchByName = useCallback(
    (name: string) => {
      return branches.find(
        (branch) => branch.name.toLowerCase() === name.toLowerCase()
      );
    },
    [branches]
  );

  return {
    getBranchById,
    getBranchNameById,
    getBranchByName,
    branches,
  };
};
