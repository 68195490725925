import React from "react";
import {
  WebAppRole,
  ICollaboratorBase,
  ICollaborator,
} from "../../../../shared/types";
import * as Yup from "yup";
import { Degree, Gender, PaymentType } from "../../../../shared/enums";
import { AddressSchema } from "../../../../shared/yup-schemas";
import { Form, Formik } from "formik";
import { SimpleFormikTextField } from "../../../../components/form/SimpleFormikTextField";
import { Box, Button, Grid, Typography } from "@mui/material";
import { SimpleFormikSelect } from "../../../../components/form/SimpleFormikSelect";
import {
  fireSwalSuccess,
  generateCollaboratorCode,
  getOptionsFromEnum,
} from "../../../../helpers";
import {
  SimpleFormikDatePicker,
  SimpleFormikSwitch,
} from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getOptionsFromFields } from "../../../../shared/helpers";
import { useNavigate } from "react-router-dom";
import { collaboratorThunks } from "../../../../store/thunks";

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  gender: Yup.string()
    .oneOf(Object.values(Gender))
    .required("Gender is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string(),
  phoneNumber2: Yup.string(),
  address: AddressSchema,
  curp: Yup.string(),
  imssNumber: Yup.string(),
  rfcCode: Yup.string(),
  emergencyContact: Yup.string(),
  emergencyContactPhone: Yup.string(),
  role: Yup.string()
    .oneOf(Object.values(WebAppRole))
    .required("Role is required"),
  imgUrl: Yup.string(),
  accessCode: Yup.string(),
  isRegistered: Yup.boolean(),
  password: Yup.string(),
  isDisplayedWeb: Yup.boolean(),
  textPresentation: Yup.string(),
  registeredDate: Yup.date(),
  lastLogin: Yup.string(),
  vacationsTakenBefore2021: Yup.number(),
  col_code: Yup.string()
    .required("Collaborator code is required")
    .matches(/^[A-Za-z]+$/, "Collaborator code must contain only letters")
    .max(3),
  col_numId: Yup.number().required("Collaborator number ID is required"),
  isActive: Yup.boolean().required("Is active is required"),
  startDate: Yup.date(),
  endDate: Yup.date(),
  position: Yup.string(),
  coverShift: Yup.boolean(),
  weeklyHours: Yup.number(),
  jobId: Yup.string().required("Job ID is required"),
  contractDate: Yup.date(),
  hasIMSS: Yup.boolean(),
  imssEnrollmentDate: Yup.date(),
  paymentType: Yup.string().oneOf(Object.values(PaymentType)),
  additionalCompensation: Yup.number(),
  degree: Yup.string().oneOf(Object.values(Degree)),
});

type Props = { initialValues: ICollaboratorBase };
export const CollaboratorForm = ({ initialValues }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { jobs } = useAppSelector((state) => state.jobs);

  const handleSubmit = async (values: ICollaboratorBase) => {
    try {
      let id = values.id;

      if (id) {
        const collaborator: ICollaborator = values as ICollaborator;
        await dispatch(collaboratorThunks.updateItem(collaborator)).unwrap();
      } else {
        const collaborator = await dispatch(
          collaboratorThunks.createItem(values)
        ).unwrap();
        const { accessCode, col_code } = collaborator;
        await fireSwalSuccess(
          `Collaborator ${col_code} created successfully. Access code: ${accessCode}`
        );
        id = collaborator.id;
      }

      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error("Error while submitting the form:", error);
    }
  };

  const handleNameChange = (
    name: string,
    value: string,
    setFieldValue: Function,
    values: ICollaboratorBase
  ) => {
    setFieldValue(name, value);
    const firstName = name === "first_name" ? value : values.first_name;
    const lastName = name === "last_name" ? value : values.last_name;

    if (firstName && lastName) {
      const collaboratorCode = generateCollaboratorCode(firstName, lastName);
      setFieldValue("col_code", collaboratorCode.toUpperCase());
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {initialValues.id ? "Edit collaborator" : "New collaborator"}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false} // Disable validation on change
        validateOnBlur={true}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <Box display="flex" flexDirection="column" gap={2}>
                {/* Primary data */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Primary Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="first_name"
                        label="First name"
                        onChange={(e) =>
                          handleNameChange(
                            "first_name",
                            e.target.value,
                            setFieldValue,
                            values
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="last_name"
                        label="Last name"
                        onChange={(e) =>
                          handleNameChange(
                            "last_name",
                            e.target.value,
                            setFieldValue,
                            values
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="email"
                        label="Email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="col_code"
                        label="Collaborator code"
                        fullWidth
                        onChange={(e) => {
                          setFieldValue(
                            "col_code",
                            e.target.value.toUpperCase()
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="col_numId"
                        label="Collaborator number ID"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSelect
                        name="jobId"
                        label="Job ID"
                        options={getOptionsFromFields(jobs, "title", "id")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSelect
                        name="role"
                        label="Role"
                        options={getOptionsFromEnum(WebAppRole)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSwitch name="isActive" label="Is active" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSwitch
                        name="isDisplayedWeb"
                        label="Is displayed web"
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* General data */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    General information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSelect
                        name="gender"
                        label="Gender"
                        options={getOptionsFromEnum(Gender)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSelect
                        name="degree"
                        label="Degree"
                        options={getOptionsFromEnum(Degree)}
                        withNoneOption
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="phoneNumber"
                        label="Phone number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="phoneNumber2"
                        label="Phone number 2"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        border="1px solid #8f8989"
                        p={2}
                        borderRadius={2}
                      >
                        <Typography variant="h6" gutterBottom>
                          Adress
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.line1"
                              label="Line 1"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.line2"
                              label="Line 2"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.city"
                              label="City"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.state"
                              label="State"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.zip"
                              label="Zip"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleFormikTextField
                              name="address.country"
                              label="Country"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="curp"
                        label="CURP"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="imssNumber"
                        label="IMSS number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="rfcCode"
                        label="RFC code"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="emergencyContact"
                        label="Emergency contact"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="emergencyContactPhone"
                        label="Emergency contact phone"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Web app data */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Web app information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="imgUrl"
                        label="Image URL"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="accessCode"
                        label="Acess Code"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSwitch
                        name="isRegistered"
                        label="Is registered"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikDatePicker
                        name="registeredDate"
                        label="Registered date"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="vacationsTakenBefore2021"
                        label="Vacations taken before 2021"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleFormikTextField
                        name="textPresentation"
                        label="Text presentation"
                        fullWidth
                        multiline
                        rows={8}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Job data */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Job information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikDatePicker
                        name="startDate"
                        label="Start date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikDatePicker
                        name="endDate"
                        label="End date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="position"
                        label="Position"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSwitch
                        name="coverShift"
                        label="Cover shift"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="weeklyHours"
                        label="Weekly hours"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikDatePicker
                        name="contractDate"
                        label="Contract date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSwitch name="hasIMSS" label="Has IMSS" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikDatePicker
                        name="imssEnrollmentDate"
                        label="IMSS enrollment date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikSelect
                        name="paymentType"
                        label="Payment type"
                        options={getOptionsFromEnum(PaymentType)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleFormikTextField
                        name="additionalCompensation"
                        label="Additional compensation"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Box>
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
