import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useParams, useNavigate } from "react-router-dom";
import {
  deleteAttendanceRecordById,
  getAttendanceRecordById,
  startUpdatingAttendanceRecord,
} from "../../../../store/thunks/attendanceRecordsThunks";
import {
  Paper,
  Typography,
  IconButton,
  Box,
  Grid,
  Divider,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { useCollaboratorHook } from "../../../../hooks/useCollaboratorsHook";
import { AttendanceRecord, BranchNames } from "../../../../shared/types";
import {
  fireSwalError,
  isAuthorizedByRole,
  isValidBranch,
} from "../../../../helpers";
import { CreatedAndUpdatedInfo } from "../../../../components";

export const AttendanceRecordView = () => {
  const { getCollaboratorCodeById, getCurrentCollaboratorRole } =
    useCollaboratorHook();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const attendanceRecord = useAppSelector(
    (state) => state.attendanceRecordsReducer.attendanceRecord
  );
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    _id: "",
    clockInBranch: "",
    clockOutBranch: "",
    collaborator: "",
    shiftDate: "",
    startTime: "",
    endTime: "",
    startLatitude: "",
    startLongitude: "",
    endLatitude: "",
    endLongitude: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getAttendanceRecordById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (attendanceRecord) {
      setFormData({
        _id: attendanceRecord._id!,
        clockInBranch: attendanceRecord.clockInBranch || "",
        clockOutBranch: attendanceRecord.clockOutBranch || "",
        collaborator: attendanceRecord.collaborator || "",
        shiftDate: attendanceRecord.shiftDate || "",
        startTime: attendanceRecord.startTime
          ? new Date(attendanceRecord.startTime).toISOString().slice(0, 16)
          : "",
        endTime: attendanceRecord.endTime
          ? new Date(attendanceRecord.endTime).toISOString().slice(0, 16)
          : "",
        startLatitude: attendanceRecord.startLatitude
          ? attendanceRecord.startLatitude.toString()
          : "",
        startLongitude: attendanceRecord.startLongitude
          ? attendanceRecord.startLongitude.toString()
          : "",
        endLatitude: attendanceRecord.endLatitude
          ? attendanceRecord.endLatitude.toString()
          : "",
        endLongitude: attendanceRecord.endLongitude
          ? attendanceRecord.endLongitude.toString()
          : "",
      });
    }
  }, [attendanceRecord]);

  const handleEdit = () => {
    if (!isAuthorizedByRole(getCurrentCollaboratorRole())) {
      fireSwalError("You are not authorized to edit this record");
      return;
    }
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (attendanceRecord) {
      setFormData({
        _id: attendanceRecord._id!,
        clockInBranch: attendanceRecord.clockInBranch || "",
        clockOutBranch: attendanceRecord.clockOutBranch || "",
        collaborator: attendanceRecord.collaborator || "",
        shiftDate: attendanceRecord.shiftDate || "",
        startTime: attendanceRecord.startTime
          ? new Date(attendanceRecord.startTime).toISOString().slice(0, 16)
          : "",
        endTime: attendanceRecord.endTime
          ? new Date(attendanceRecord.endTime).toISOString().slice(0, 16)
          : "",
        startLatitude: attendanceRecord.startLatitude
          ? attendanceRecord.startLatitude.toString()
          : "",
        startLongitude: attendanceRecord.startLongitude
          ? attendanceRecord.startLongitude.toString()
          : "",
        endLatitude: attendanceRecord.endLatitude
          ? attendanceRecord.endLatitude.toString()
          : "",
        endLongitude: attendanceRecord.endLongitude
          ? attendanceRecord.endLongitude.toString()
          : "",
      });
    }
  };

  const handleSave = () => {
    const updatedRecord = {
      ...formData,
      clockInBranch: isValidBranch(formData.clockInBranch)
        ? formData.clockInBranch
        : BranchNames.Urban,
      clockOutBranch: isValidBranch(formData.clockOutBranch)
        ? formData.clockOutBranch
        : (BranchNames.Urban as BranchNames),
    };
    dispatch(
      startUpdatingAttendanceRecord(
        updatedRecord as unknown as AttendanceRecord
      )
    );
    setIsEditing(false);
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      dispatch(deleteAttendanceRecordById(attendanceRecord?._id!));
      navigate("/dashboard/attendance-records/history");
    }
  };

  if (!attendanceRecord) return <div>Loading...</div>;

  return (
    <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        {isEditing ? "Edit Attendance Record" : "Attendance Record Details"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isEditing ? (
            <>
              <TextField
                label="Clock In Branch"
                value={formData.clockInBranch}
                onChange={(e) =>
                  setFormData({ ...formData, clockInBranch: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Clock Out Branch"
                value={formData.clockOutBranch}
                onChange={(e) =>
                  setFormData({ ...formData, clockOutBranch: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Collaborator"
                value={formData.collaborator}
                onChange={(e) =>
                  setFormData({ ...formData, collaborator: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Shift Date"
                value={formData.shiftDate}
                onChange={(e) =>
                  setFormData({ ...formData, shiftDate: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Start Time"
                type="datetime-local"
                value={formData.startTime}
                onChange={(e) =>
                  setFormData({ ...formData, startTime: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Time"
                type="datetime-local"
                value={formData.endTime}
                onChange={(e) =>
                  setFormData({ ...formData, endTime: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Start Latitude"
                value={formData.startLatitude}
                onChange={(e) =>
                  setFormData({ ...formData, startLatitude: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Start Longitude"
                value={formData.startLongitude}
                onChange={(e) =>
                  setFormData({ ...formData, startLongitude: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="End Latitude"
                value={formData.endLatitude}
                onChange={(e) =>
                  setFormData({ ...formData, endLatitude: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="End Longitude"
                value={formData.endLongitude}
                onChange={(e) =>
                  setFormData({ ...formData, endLongitude: e.target.value })
                }
                fullWidth
                margin="normal"
              />
            </>
          ) : (
            <>
              <Typography variant="subtitle1">
                <strong>Clock In Branch:</strong>{" "}
                {attendanceRecord.clockInBranch}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Clock Out Branch:</strong>{" "}
                {attendanceRecord.clockOutBranch}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Collaborator:</strong>{" "}
                {getCollaboratorCodeById(attendanceRecord.collaborator)}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Shift Date:</strong> {attendanceRecord.shiftDate}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Start Time:</strong>{" "}
                {attendanceRecord.startTime
                  ? format(new Date(attendanceRecord.startTime), "PPpp")
                  : "N/A"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>End Time:</strong>{" "}
                {attendanceRecord.endTime
                  ? format(new Date(attendanceRecord.endTime), "PPpp")
                  : "N/A"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Start Latitude:</strong>{" "}
                {attendanceRecord.startLatitude}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Start Longitude:</strong>{" "}
                {attendanceRecord.startLongitude}
              </Typography>
              <Typography variant="subtitle1">
                <strong>End Latitude:</strong> {attendanceRecord.endLatitude}
              </Typography>
              <Typography variant="subtitle1">
                <strong>End Longitude:</strong> {attendanceRecord.endLongitude}
              </Typography>
              <Box>
                <CreatedAndUpdatedInfo
                  createdAt={attendanceRecord.createdAt?.toString()}
                  createdBy={attendanceRecord.createdBy}
                  updatedAt={attendanceRecord.updatedAt?.toString()}
                  updatedBy={attendanceRecord.updatedBy}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {isEditing ? (
              <>
                <IconButton
                  color="primary"
                  onClick={handleSave}
                  sx={{ marginRight: 1 }}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancelEdit}>
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  color="primary"
                  onClick={handleEdit}
                  sx={{ marginRight: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
